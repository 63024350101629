import { useCallback } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { RouterLink } from 'src/components/router-link';
import { useAuth } from 'src/hooks/use-auth';
// import { paths } from 'src/paths';
import { useUser } from 'src/hooks/use-user';
import { useMounted } from 'src/hooks/use-mounted';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonIcon from '@mui/icons-material/Person';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const isMounted = useMounted();
  const auth = useAuth(); 
  const user = useUser();
  console.log('user',user);

 
  const handleLogout = useCallback(async () => {
    try {
      onClose?.();
      auth.signOut();
      if (isMounted()) {
        window.location.reload();        
      } 
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  }, [auth, onClose,isMounted]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 180, top:'60px!important',left:'auto!important', right:'25px' } }}
      {...other}>
      <Box
      
      sx={{display:{xs:'block', sm:'block', lg:'none', md:'none'},p: 2 }} 
      >
        <Typography variant="body1"  
        sx={{display:{xs:'block', sm:'block', lg:'none', md:'none'}, paddinLeft:'15px'}}>
          {user.firstName} {user.lastName}
        </Typography>
        {/* <Typography
          color="text.secondary"
          variant="body2"
        >
          {user.email}
        </Typography>*/}
      </Box>
      <Divider 
      sx={{display:{xs:'block', sm:'block', lg:'none', md:'none'}}} /> 
      <Box 
      sx={{ p: .1 ,border:'1px solid #eee',
      '& .MuiListItemButton-root:hover':{backgroundColor:'rgba(50,16,137,.3)',color:'rgba(50,16,137,1)'},
      '& .MuiListItemButton-root:hover path':{fill:'rgba(50,16,137,1)'},}}>

      
     
        <ListItemButton
          component={RouterLink}
          to={'/profile'}
          onClick={onClose}
          sx={{
            borderRadius: '5px 5px 0 0',
            px: 1,
            py: 0.5,
            
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <PersonIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1" 
              sx={{fontSize:'12px'}}>
                Profile
              </Typography>
            )}
          />
        </ListItemButton>
        {/* <ListItemButton
          component={RouterLink}
          href={paths.dashboard.account}
          onClick={onClose}
          sx={{
            borderRadius: 0,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <SettingsOutlinedIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1" 
              sx={{fontSize:'12px'}}>
                Settings
              </Typography>
            )}
          />
        </ListItemButton> */}

        <Divider sx={{ my: '0 !important' }} />

        <ListItemButton
          onClick={handleLogout}
          sx={{
            borderRadius: '0px 0px 5px 5px',
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <LogoutOutlinedIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1" 
              sx={{fontSize:'12px'}}>
                Logout
              </Typography>
            )}
          />
        </ListItemButton>
        {/* <ListItemButton
          component={RouterLink}
          href={paths.dashboard.index}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <CreditCard01Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                Billing
              </Typography>
            )}
          />
        </ListItemButton> */}
      </Box>
      
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
