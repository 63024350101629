import { Toaster as HotToaster } from 'react-hot-toast';
import { useTheme } from '@mui/material/styles';

export const Toaster = () => {
  const theme = useTheme();

  return (
    <HotToaster
      position="top-center"

      containerStyle={{
        top: '48%',
        left: '0',
        bottom: 0,
        right: '0',
      }}
      toastOptions={{
        duration: 2500,
        success: {
          style: {
            border: '1px solid  green',
          },
        },
        error: {
          style: {
            border: '1px solid red',
          },
        },
        style: {
          backdropFilter: 'blur(6px)',
          background: '#fff',
          color: '#000',
          boxShadow: theme.shadows[30],
          padding: '16px',
        }
      }}
    />
  );
};
