import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { ReactComponent as SmartLeaderWhiteLogo } from "../../icons/untitled-ui/smartLeaderLogin.svg";
import { ReactComponent as SmartLeaderLogo } from "../../icons/untitled-ui/smartLeader.svg";
import Typography from "@mui/material/Typography";
//import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
//import { RECAPTCHA_KEY } from "src/config";

export const Layout = (props) => {
  const { children } = props;

  return (
    <>
  {/* <GoogleReCaptchaProvider
    reCaptchaKey={RECAPTCHA_KEY}
    scriptProps={{
      async: false,
      defer: false,
      appendTo: "head",
      nonce: undefined,
    }}
  > */}
    <Grid container 
    sx={{ height: "100vh" }}>
      <Grid  
        item
        xs={12}
        md={4}
        sx={{ height: "100%",p: { md: 3, xs: 1, lg: 3} }}
      >
        <Stack sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}>
          <SmartLeaderLogo />
        </Stack>
        <Stack sx={{p: { md: 5, xs: 2, lg: 6} }}>{children}</Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        sx={{
          backgroundImage: "url(./assets/backgroundsSvg/loginBg.svg)",
          backgroundSize: "cover",
          height: "100%",
          borderRadius: "100px 0 0 100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack justifyContent="center" 
        alignItems="center" 
        direction="column">
          <SmartLeaderWhiteLogo />
          <Typography fontSize="24px" 
          color="white"　
          sx={{ m: 2, textAlign:"center" }}>
            <span style={{ fontWeight: 700, fontStyle: "italic" }}>
              Develop
            </span>{" "}
            leaders,
            <span style={{ fontWeight: 700, fontStyle: "italic" }}>
              &nbsp;build
            </span>{" "}
            high performing teams,{" "}
            <span style={{ fontWeight: 700, fontStyle: "italic" }}>create</span>{" "}
            empowering work experiences
          </Typography>
        </Stack>
      </Grid>
    </Grid>
   { /* </GoogleReCaptchaProvider> */}
   </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};