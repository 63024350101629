import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import * as SldButtons from "src/theme/base/SldButtons";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Url } from "src/config";
import { Delete } from "@mui/icons-material";
// eslint-disable-next-line  
import { JoyrideContext } from 'src/contexts/JoyrideContext';


const firstNameValidation = Yup.string()
  .trim("No unwanted spaces allowed")
  .matches(/^[^<>\\/]+$/, "Invalid FirstName")
  .matches(
    /^[a-zA-ZāĀēĒīĪōŌūŪüÜäÄâÂåÅãÃàÀáÁéÉëËêÊèÈíÍïÏîÎìÌóÓöÖôÔòÒúÚüÜûÛùÙ0-9' \-!]+(?: [a-zA-ZāĀēĒīĪōŌūŪüÜäÄâÂåÅãÃàÀáÁéÉëËêÊèÈíÍïÏîÎìÌóÓöÖôÔòÒúÚüÜûÛùÙ0-9' \-!]+)*$/,
    "Invalid FirstName"
  )
  .matches(/^(?!.*\s{2})/, "No unwanted spaces allowed")
  .strict(true)
  .required("FirstName is required");

const lastNameValidation = Yup.string()
  .trim("No unwanted spaces allowed")
  .matches(/^[^<>\\/]+$/, "Invalid LastName")
  .matches(
    /^[a-zA-ZāĀēĒīĪōŌūŪüÜäÄâÂåÅãÃàÀáÁéÉëËêÊèÈíÍïÏîÎìÌóÓöÖôÔòÒúÚüÜûÛùÙ0-9' \-!]+(?: [a-zA-ZāĀēĒīĪōŌūŪüÜäÄâÂåÅãÃàÀáÁéÉëËêÊèÈíÍïÏîÎìÌóÓöÖôÔòÒúÚüÜûÛùÙ0-9' \-!]+)*$/,
    "Invalid LastName"
  )
  .matches(/^(?!.*\s{2})/, "No unwanted spaces allowed")
  .strict(true)
  .required("LastName is required");

const ConfirmationDialog = ({ isOpen, onConfirm, onClose }) => (
  <Dialog open={isOpen}
onClose={onClose}
maxWidth="sm"
fullWidth>
    <DialogTitle>Confirm Sending Request</DialogTitle>
    <DialogContent>
      <Typography>
        Please review that all the information you have entered (specifically
        email address) in the request is accurate before submitting.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}
color="primary">
        Cancel
      </Button>
      <Button onClick={onConfirm}
color="primary"
variant="contained">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

const formatDate = (dateString) => {
  if (!dateString) return null; // Return null if dateString is empty

  const date = new Date(dateString);
  if (isNaN(date)) {
    return null; // Return null if the date is invalid
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const AddMemberPopUp = ({
  isOpen,
  handleClose,
  categotyId,
  category_name,
}) => {
  const initialTeamMember = {
    firstName: "",
    lastName: "",
    userName: "", // Assuming this should be updated based on other fields
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: 4,
    jobTitle: "",
    memberRole: "",
    country: "",
    city: "",
  };

  const [teamMembers, setTeamMembers] = useState([]);
  const [currentMember, setCurrentMember] = useState(initialTeamMember);
  const [errors, setErrors] = useState({});
  const [disableAddMore, setDisableAddMore] = useState(true); // Enable Add More by default
  const [disableSendRequest, setDisableSendRequest] = useState(true);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [genders, setGenders] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [genderMap, setGenderMap] = useState({});
  const [countryMap, setCountryMap] = useState({});
  const [roleMap, setRoleMap] = useState({});

  console.log(teamMembers);
  console.log(disableSendRequest);

  const accessToken = sessionStorage.getItem("accessToken");

  useEffect(() => {
    const fetchGenders = async () => {
      try {
        const response = await axios.get(`${Url}/customer/genders`, {
          headers: {
            "access-token": accessToken,
          },
        });
        setGenders(response.data.details);
        const genderMapping = response.data.details.reduce((acc, gender) => {
          acc[gender.id] = gender.name;
          return acc;
        }, {});
        setGenderMap(genderMapping);
      } catch (error) {
        console.error("Error fetching genders:", error);
      }
    };

    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${Url}/customer/countries`, {
          headers: {
            "access-token": accessToken,
          },
        });
        setCountriesList(response.data.details);
        const countryMapping = response.data.details.reduce((acc, country) => {
          acc[country.id] = country.name;
          return acc;
        }, {});
        setCountryMap(countryMapping);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${Url}/customer/roles`, {
          headers: {
            "access-token": accessToken,
          },
        });
        setRoles(response.data.details);
        const roleMapping = response.data.details.reduce((acc, role) => {
          acc[role.id_admin_role] = role.admin_role_name;
          return acc;
        }, {});
        setRoleMap(roleMapping);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchGenders();
    fetchCountries();
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (field, value) => {
    if (field === "phoneNumber") {
      // Remove non-numeric characters
      value = value.replace(/[^0-9]/g, "");
    }

    const newMember = { ...currentMember, [field]: value };

    if (field === "email") {
      newMember["userName"] = value; // Update `userName` based on `email`
    }

    setCurrentMember(newMember);
    validateField(newMember, field, value);
  };
  const validateField = (newMember, field, value) => {
    const newErrors = { ...errors };
    let errorMessages = newErrors || {};

    if (field === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      if (!emailRegex.test(value)) {
        newErrors.email = "Invalid email format";
      } else if (teamMembers.some((m) => m.email === value)) {
        newErrors.email = "Email already exists";
      } else {
        delete newErrors.email;
      }
    }

    if (field === "dateOfBirth") {
      const currentDate = getCurrentDate();
      if (value > currentDate) {
        errorMessages.dateOfBirth = "Date of birth cannot be in the future";
      } else {
        delete errorMessages.dateOfBirth;
      }
    }

    if (field === "firstName") {
      try {
        firstNameValidation.validateSync(value);
        delete newErrors.firstName;
      } catch (err) {
        newErrors.firstName = err.message;
      }
    }

    if (field === "lastName") {
      try {
        lastNameValidation.validateSync(value);
        delete newErrors.lastName;
      } catch (err) {
        newErrors.lastName = err.message;
      }
    }

    setErrors(errorMessages);
    checkFormValidity(teamMembers, newMember, errorMessages);
  };

  const checkFormValidity = (members, currentMember, errors) => {
    const isCurrentMemberValid =
      currentMember.firstName.trim() !== "" &&
      currentMember.lastName.trim() !== "" &&
      currentMember.email.trim() !== "" &&
      currentMember.country !== "" &&
      currentMember.city.trim() !== "" &&
      currentMember.memberRole !== "" &&
      !errors?.firstName &&
      !errors?.lastName &&
      !errors?.email &&
      !errors?.dateOfBirth;

    const areAllMembersValid = members.every(
      (member) =>
        member.firstName.trim() !== "" &&
        member.lastName.trim() !== "" &&
        member.email.trim() !== "" &&
        member.country !== "" &&
        member.city.trim() !== "" &&
        member.memberRole !== "" &&
        !errors?.firstName &&
        !errors?.lastName &&
        !errors?.email &&
        !errors?.dateOfBirth
    );

    setDisableAddMore(!isCurrentMemberValid);
    setDisableSendRequest(!areAllMembersValid); // Changed this line to always enable if there are members
  };

  const handleAddRow = () => {
    if (
      currentMember.firstName.trim() === "" ||
      currentMember.lastName.trim() === "" ||
      currentMember.email.trim() === "" ||
      currentMember.country === "" ||
      currentMember.city.trim() === "" ||
      currentMember.memberRole === ""
    ) {
      toast.error("Please fill in all required fields before adding a member.");
      return;
    }

    const newMember = { ...currentMember };
    setTeamMembers([...teamMembers, newMember]);
    setCurrentMember(initialTeamMember);
    setErrors({});
    setDisableAddMore(true);
    setDisableSendRequest(false); // Enable Send Request button
  };

  const handleDeleteMember = (index) => {
    const newTeamMembers = [...teamMembers];
    newTeamMembers.splice(index, 1);
    setTeamMembers(newTeamMembers);
    setDisableSendRequest(newTeamMembers.length === 0);
  };

  const handleSendRequest = () => {
    setConfirmationDialogOpen(true);
  };

  const handleConfirmSendRequest = async () => {
    const requestData = teamMembers.map((member) => ({
      firstName: member.firstName,
      lastName: member.lastName,
      userName: member.userName,
      email: member.email,
      phoneNumber: member.phoneNumber,
      dateOfBirth: formatDate(member.dateOfBirth),
      genderId: member.gender,
      jobTitle: member.jobTitle,
      memberRole: member.memberRole,
      country: member.country,
      city: member.city,
    }));

    try {
      const response = await axios.post(
        `${Url}/customer/request_user`,
        {
          type: "add_user",
          reqData: requestData,
          category_id: categotyId,
          category_name,
        },
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );

      if (response.status === 200) {
        resetForm();
        setConfirmationDialogOpen(false);
        handleClose();
        toast.success("Add user request sent successfully!");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const resetForm = () => {
    setTeamMembers([]);
    setCurrentMember(initialTeamMember);
    setErrors({});
    setDisableAddMore(true);
    setDisableSendRequest(true);
  };

  const currentDate = getCurrentDate();

  return (
    <>
      <Dialog open={isOpen} 
onClose={handleClose}
maxWidth="md"
fullWidth>
        <DialogTitle>Add Team Members</DialogTitle>
        <DialogContent >
          <Box mt={2}>
            <Box
              mb={2}
              pb={2}
              borderBottom="1px solid #e0e0e0"
              position="relative"
               

            >
              <Typography variant="h6"   
gutterBottom>
                Team Member
              </Typography>
              <Grid container
spacing={2}             
>
               <Grid item
xs={12}
sm={6}>
                  <TextField 
                   className="joyride-target-ls6"
                    label="First Name"
                    value={currentMember.firstName}
                    onChange={(e) =>
                      handleInputChange("firstName", e.target.value)
                    }
                    fullWidth
                    required
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                  />
                </Grid>
                <Grid item
xs={12}
sm={6}>
                  <TextField
                    label="Last Name"
                    value={currentMember.lastName}
                    onChange={(e) =>
                      handleInputChange("lastName", e.target.value)
                    }
                    fullWidth
                    required
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                  />
                </Grid>
                <Grid item
xs={12}
sm={6}>
                  <TextField
                    label="User Name"
                    value={currentMember.userName}
                    onChange={(e) =>
                      handleInputChange("userName", e.target.value)
                    }
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item
xs={12}
sm={6}>
                  <TextField
                    label="Email Address"
                    value={currentMember.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                    fullWidth
                    required
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item
xs={12}
sm={6}>
                  <TextField
                    label="Phone Number"
                    value={currentMember.phoneNumber}
                    onChange={(e) =>
                      handleInputChange("phoneNumber", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item
xs={12}
sm={6}>
                  <TextField
                    type="date"
                    label="Date of Birth"
                    value={currentMember.dateOfBirth}
                    onChange={(e) =>
                      handleInputChange("dateOfBirth", e.target.value)
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: currentDate,
                    }}
                    error={!!errors.dateOfBirth}
                    helperText={errors.dateOfBirth}
                  />
                </Grid>
                <Grid item
xs={12}
sm={6}>
                  <TextField
                    label="Gender"
                    select
                    value={currentMember.gender}
                    onChange={(e) =>
                      handleInputChange("gender", e.target.value)
                    }
                    fullWidth
                  >
                    {genders.map((gender) => (
                      <MenuItem key={gender.id}
value={gender.id}>
                        {gender.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item
xs={12}
sm={3}>
                  <TextField
                    label="Job Title"
                    value={currentMember.jobTitle}
                    onChange={(e) =>
                      handleInputChange("jobTitle", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item
xs={12}
sm={3}>
                  <TextField
                    label="Member Role"
                    select
                    value={currentMember.memberRole}
                    onChange={(e) =>
                      handleInputChange("memberRole", e.target.value)
                    }
                    fullWidth
                    required
                  >
                    {roles.map((role) => (
                      <MenuItem
                        key={role.id_admin_role}
                        value={role.id_admin_role}
                      >
                        {role.admin_role_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item
xs={12}
sm={6}>
                  <TextField
                    label="Country"
                    select
                    value={currentMember.country}
                    onChange={(e) =>
                      handleInputChange("country", e.target.value)
                    }
                    fullWidth
                    required
                  >
                    {countriesList.map((country) => (
                      <MenuItem key={country.id}
value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item
xs={12}
sm={6}>
                  <TextField
                    label="City"
                    value={currentMember.city}
                    onChange={(e) => handleInputChange("city", e.target.value)}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}
textAlign="start">
              <SldButtons.PurpleButton
                onClick={handleAddRow}
                color="primary"
                disabled={disableAddMore}
                variant="contained"
              >
                Add More Members
              </SldButtons.PurpleButton>
            </Box>
          </Box>
          <Box mt={4}>
            <Typography variant="h6"
gutterBottom>
              Team Members Details
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="team-members-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell
                      style={{
                        maxWidth: "150px",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>Member Role</TableCell>
                    <TableCell>Date of Birth</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Job Title</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teamMembers.map((member, index) => (
                    <TableRow key={index}>
                      <TableCell>{`${member.firstName} ${member.lastName}`}</TableCell>
                      <TableCell
                        style={{
                          maxWidth: "150px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {member.email}
                      </TableCell>
                      <TableCell>{genderMap[member.gender]}</TableCell>
                      <TableCell>{countryMap[member.country]}</TableCell>
                      <TableCell>{member.city}</TableCell>
                      <TableCell>{roleMap[member.memberRole]}</TableCell>
                      <TableCell>{member.dateOfBirth}</TableCell>
                      <TableCell>{member.phoneNumber}</TableCell>
                      <TableCell>{member.jobTitle}</TableCell>
                      <TableCell>
                        <Delete onClick={() => handleDeleteMember(index)} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions 
        className='joyride-target-ls66'
        >
          <SldButtons.PurpleButton
            variant="contained"
            onClick={handleSendRequest}
            color="primary"
            disabled={teamMembers.length === 0}
          >
            Send Request
          </SldButtons.PurpleButton>
          <SldButtons.BlackButtonO
            onClick={() => {
              resetForm();
              handleClose();
            }}
            color="primary"
          >
            Cancel
          </SldButtons.BlackButtonO>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        isOpen={confirmationDialogOpen}
        onConfirm={handleConfirmSendRequest}
        onClose={() => setConfirmationDialogOpen(false)}
      />
    </>
  );
};
