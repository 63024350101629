import {Button,Stack,Box,Grid} from "@mui/material";
import { styled } from "@mui/system";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
// eslint-disable-next-line
import TextField from '@mui/material/TextField';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
// eslint-disable-next-line
import InputLabel from '@mui/material/InputLabel'
import { purple } from "src/theme/colors";
import { NavLink } from 'react-router-dom';
//Tab
const SldTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#583F99",
  },
});
const SldTab = styled((props) => <Tab disableRipple
{...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "#535B6680",
    "&.Mui-selected": {
      color: "#583F99",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

//check box
const SldCheckBox = styled((props) => <Checkbox disableRipple
{...props} />)(
  ({ theme }) => ({
    borderRadius: 3,
    width: 30,
    height: 30,
    
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
    "& .          -root": {
      fontSize:28,
      width: 20, // Set the width of the inner checkbox
      height: 20, // Set the height of the inner checkbox
    },
  })
);
// toggle button ios type
const IOSSwitch = styled(({ onSwitch, ...props }) => (
  <Switch focusVisibleClassName=".Mui-focusVisible"
// disableRipple
onChange={onSwitch}
{...props} />
))(({ theme }) => ({
  width: 28,
  height: 18,
  padding: 0,
  '&.MuiSwitch-root':{position:'absolute', right:'3px'},
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 13,
    height: 13,backgroundColor:'#fff',
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,

    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
/*login Inpt*/

const LoginTextField =  styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    // Default styles for the input base
    fontSize: '1rem',
    borderColor: 'rgba(83,91,1,.2)',
    color:'rgba(83,91,1,.2)',
    lineHeight:'1.2rem',
    boxShadow:'rgb(88, 63, 153) 0 0 0 0px',
    borderRadius: "100px", 
    width: '100%',
  
    '& .MuiInputBase-input': {
      color:'rgba(83,91,1,.2)',
    },
    
    
    '&:hover': {
      borderColor: 'rgb(88, 63, 153)',
      boxShadow:'rgb(88, 63, 153) 0 0 0 0px',
      color:'rgb(88, 63, 153)',
    },
    '&.Mui-focused': {
      borderColor: 'rgb(88, 63, 153)',
      boxShadow:'rgb(88, 63, 153) 0 0 0 0px',
      color:'rgb(88, 63, 153)',
      '& .MuiInputBase-input': {
        color:'rgb(88, 63, 153)!important',
        
      },
      '& .MuiInputLabel-root': {
        transform: 'translateY(-25px)',
        backgroundColor:'#fff',
        width: '120px',
    padding: '0 5px',
    color:'rgb(88, 63, 153)',

      },
    },
    '&.Mui-error': {
      borderColor: '#F04438',
      boxShadow:'#F04438 0 0 0 0px',
      color:'#F04438!important',
      '& .MuiInputLabel-root': {
        color:'#F04438!important',
      },
      '& .MuiInputBase-input': {
        color:'#F04438!important',
        
      },
      
    },
    '&.Mui-filled': {
      color:'rgb(88, 63, 153)',
      boxShadow:'#F04438 0 0 0 0px',
      '& .MuiInputBase-input': {
        color:'rgb(88, 63, 153)',
        
      },
      // Styles for filled state
      
    },
  },
  '& .MuiInputBase-input': {
    paddingTop: '10px', // Remove padding for the input
    
  },
  
  '& .MuiInputLabel-root': {
        width: '120px',
    textAlign:'center',
    // Default styles for the label
    position: 'relative',
    transform: 'translate(10px, 30px) scale(1)'
  },
  '& .Mui-error.MuiInputLabel-animated.MuiInputLabel-filled':{
    transform: 'translate(10px, 30px) scale(1)',
    //backgroundColor:'#fff',
  },
  '& .Mui-error.MuiInputLabel-animated.MuiInputLabel-filled.MuiInputLabel-shrink':
  {transform: 'translate(10px, 9px) scale(.8)',backgroundColor:'#fff', color:'#F04438!important',},
'& .MuiInputLabel-animated':{backgroundColor:'#fff',},
  '& .Mui-focused.MuiInputLabel-animated.MuiInputLabel-shrink': {
    color:'rgb(88, 63, 153)!important', // Change label color when filled
    
    transform: 'translate(10px, 9px) scale(.8)',
    
  },
'&.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline':{
  color:'rgb(88, 63, 153)',
  boxShadow:'#F04438 0 0 0 0px',
  

},
'& .MuiInputBase-input.MuiFilledInput-input':{color:'rgb(88, 63, 153)',},
  '& .MuiInput-underline:before': {
    // Default styles for the underline
    
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    // Hover styles for the underline
   
  },
  '& .MuiInput-underline:after': {
    // Focused styles for the underline
    
  },
  '& .Mui-error .MuiInput-underline:before': {
    // Error styles for the underline
    borderColor: '#F04438',
      boxShadow:'#F04438 0 0 0 0px',
  },
  '& .MuiInputBase-root.MuiFilledInput-root:hover':{backgroundColor:'#fff',boxShadow:'0 1px 1px 1px'}

 
}));

const CultureAvatarBig = styled(Avatar)(({ theme }) => ({
  width: 75,
  height: 75,
  borderRadius: 15,
  backgroundColor: 'transparent',
  padding:'10px',
  border:'1px solid #ddd'
}));
const IbPrevNext = styled(IconButton)(({ theme }) => ({
  '&.MuiButtonBase-root .MuiTouchRipple-root':{display:'none'},
  '&.MuiButtonBase-root svg':{height:'18px',
  width:'18px',
  fontSize:'small',
   
    '& circle':{stroke:'rgba(83,91,102,.2)', strokeWidth:'.5'},
  

    '&:hover circle':{fillOpacity:'1',fill:'white',stroke:'rgb(88, 63, 153)'},
    '&:hover path':{fill:"rgb(88, 63, 153)"}
}}));

const ArrowAnimated = styled(Box)(({ theme }) => ({
  display:'flex',
  '& svg': {
    width: 23,
    height: 23,
    '&:hover rect':{fillOpacity:'1'},
    '& path': {
      transition: 'transform 0.3s ease-in-out', // Smooth transition
    },}
}));

const CwCode = styled(Box)(({ theme }) => ({
  borderRadius:'50%',width:'16px', height:'16px', position:'absolute', right:'32px', color:'#fff',fontWeight:'600',lineHeight:'16px',fontSize:'10px'
}));
const CwnameTrunk = styled(Box)(({ theme }) => ({
  textAlign: 'left',

  // Media queries for resolutions between 1200px and 1400px
  [theme.breakpoints.between(1200, 1400)]: {
    width: '50%', // Apply specific styles here
    fontSize: '11px', // Example: Changing font size
     wordBreak:'break-all!important'
  },

  // Media query for screen widths above 1500px
  [theme.breakpoints.up(1500)]: {
    width: '60%',
  },

  // Media query for screen widths below 1199px
  [theme.breakpoints.down(1199)]: {
    width: 'auto',
  },
}));
const MtpCheadSmi = styled(Avatar)(({ theme }) => ({
'&.MuiAvatar-root':{width:'25px', height:'25px'},
          '& svg':{width:'15px', height:'15px'} 
        }));
const CultureAvatarSmall = styled(Avatar)(({ theme }) => ({

  borderRadius: 10,
  backgroundColor: 'transparent',
  padding:'2px',
  border:'1px solid #ddd',
  marginBottom:'5px'
}));
// Survey Due Button
const SurveyDue = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: 'rgb(88, 63, 153)',
  padding:'20px',
  border:'1px solid# 1a1e21',
  borderRadius: "15px",
  boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.14)",
  display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%', // Adjust width as needed
  lineHeight:'1',
  '&:hover': {
    backgroundColor: 'rgba(88, 63, 153,.2)',
    border:'1px solid rgb(88, 63, 153)',
    

  },
  
}));


/*Purple Raidus 100*/
const PurpleButtonCap = styled(Button)(({ theme }) => ({
  backgroundColor: "rgb(88, 63, 153)",
  color: theme.palette.common.white,
  borderRadius: "100px",
  lineHeight:'1',
  '&:hover': {
    backgroundColor: '#1a1e21',
    border:'1px solid# 1a1e21',
    color: '#FFFFFF!important',
    '& .MuiSvgIcon-root': {
     // Set the desired animation duration and timing 
      transform: 'scale(1) translateX(5px)',
    },

  },
  '& .MuiSvgIcon-root': {
    transition: 'transform 0.3s ease-in-out', // Set the desired animation duration and timing 
    transform: 'scale(1) translateX(-5px)',
  },
}));

/*Purple Raidus 100 Outline*/
const PurpleButtonCapO = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  border: "1px solid rgb(88, 63, 153)",
  color: "rgb(88, 63, 153)",
  borderRadius: "100px",
  '&:hover': {
    backgroundColor: '#1a1e21',
    border:'1px solid# 1a1e21',
    color: '#FFFFFF!important',
    '& .MuiSvgIcon-root': {
     // Set the desired animation duration and timing 
      transform: 'scale(1) translateX(5px)',
    },

  },
  '& .MuiSvgIcon-root': {
    transition: 'transform 0.3s ease-in-out', // Set the desired animation duration and timing 
    transform: 'scale(1) translateX(-5px)',
  },
}));

/*Purple Raidus 100 Outline Prev*/
const PurpleButtonCapOPre = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  border: "1px solid rgb(88, 63, 153)",
  color: "rgb(88, 63, 153)",
  borderRadius: "100px",
  '& .MuiButton-startIcon':{marginLeft:0},
  '&:hover': {
    backgroundColor: '#1a1e21',
    border:'1px solid# 1a1e21',
    color: '#FFFFFF!important',
    '& .MuiSvgIcon-root': {
     // Set the desired animation duration and timing 
      transform: 'scale(1) translateX(-5px)',
    },

  },
  '& .MuiSvgIcon-root': {
    transition: 'transform 0.3s ease-in-out', // Set the desired animation duration and timing 
    transform: 'scale(1) translateX(5px)',
    marginLeft:0,
  },
}));

/*Purple Raidus 8*/
const PurpleButton = styled(Button)(({ theme }) => ({
  backgroundColor: "rgb(88, 63, 153)",
  color: theme.palette.common.white,
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#1a1e21",
    '& .MuiSvgIcon-root': {
      // Set the desired animation duration and timing 
       transform: 'scale(1) translateX(5px)',
     },
 
   },
   '& .MuiSvgIcon-root': {
     transition: 'transform 0.3s ease-in-out', // Set the desired animation duration and timing 
     transform: 'scale(1) translateX(-5px)',
   },
}));
/*Purple Raidus 8 Outline*/
const PurpleButtonO = styled(Button)(({ theme }) => ({
  border: "1px solid rgb(88, 63, 153)",
  color: "rgb(88, 63, 153)",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#1a1e21",
    border: "1px solid #1a1e21",
    color: "rgb(255,255,255)",
  },
}));
/*Black Raidus 8 */
const BlackButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#1a1e21",
  color: theme.palette.common.white,
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "rgb(88, 63, 153)",
    border: "1px solid rgb(88, 63, 153)",
    color: "#FFFFFF",
  },
}));
/*Black Raidus 100 */
const BlackButtonCap = styled(Button)(({ theme }) => ({
  backgroundColor: "#1a1e21",
  border: "1px solid #1a1e21",
  color: theme.palette.common.white,
  borderRadius: "100px",
  fontWeight: "600",
  "&:hover": {
    backgroundColor: "rgb(88, 63, 153)",
    border: "1px solid rgb(88, 63, 153)",
    color: "#FFFFFF",
  },
}));
/*Black Raidus 8 Outline*/
const BlackButtonO = styled(Button)(({ theme }) => ({
  border: "1px solid #1a1e21",
  color: "#1a1e21",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#1a1e21",
    border: "1px solid #1a1e21",
    color: "#FFFFFF",
  },
}));
/*Black Raidus 100 Outline*/
const BlackButtonCapO = styled(Button)(({ theme }) => ({
  border: "1px solid #1a1e21",
  backgroundColor: "#FFFFFF",
  color: "#1a1e21",
  borderRadius: "100px",
  "&:hover": {
    backgroundColor: "#1a1e21",
    border: "1px solid #1a1e21",
    color: "#FFFFFF",
  },
}));
/*White Raidus 8 */
const WhiteButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  color: "#212427",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#212427",
    border: "1px solid #212427",
    color: "#FFFFFF",
  },
}));

const WhiteButtonSIcon = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  color: "rgb(88, 63, 153)",
  border: "1px solid rgb(88, 63, 153)",
  borderRadius: "8px",
  '@media (max-width: 600px)': {
    padding:'5px',
    minWidth:0,
    '& .MuiButton-startIcon':{margin:0}
  },
  '& .MuiButton-startIcon': {color: "rgb(88, 63, 153)",
  'path':{fill: "rgb(88, 63, 153)"}
},
  "&:hover": {
    backgroundColor: "#212427",
    border: "1px solid #212427",
    color: "#FFFFFF",
    '& .MuiButton-startIcon': {color: "#ffffff",
  'path':{fill: "#ffffff"}
  },}
    
}));
/*white button 50 */
const WhiteButtonCap = styled(Button)(({ theme }) => ({
  backgroundColor: '#ffffff',
  color: 'rgb(88, 63, 153)',
  borderRadius: '50px',
  marginTop:'0',
  fontWeight:'600',
 
  '&:hover': {
    backgroundColor: '#1a1e21',
    color: '#FFFFFF!important',
    '& .MuiSvgIcon-root': {
     // Set the desired animation duration and timing 
      transform: 'scale(1) translateX(5px)',
    },

  },
  '& .MuiSvgIcon-root': {
    transition: 'transform 0.3s ease-in-out', // Set the desired animation duration and timing 
    transform: 'scale(1) translateX(-5px)',
  },
}));



const SurveyClock = styled(Stack)(({ theme }) => ({
  borderRadius: "10px",
  flexDirection:'row',
  padding:'10px',
  border: "1px solid #fff",
  backgroundColor: "rgba(255,255,255,.3)",
  cursor:"default",
  color:'#fff',
  alignItems:'center',
  marginTop:'30px!important',
  marginBottom:'20px!important',
  '& .MuiTypography-subtitle1':{paddingLeft:'4px'},
  '& .MuiTypography-subtitle1 span':{fontWeight:'600'}

  
    }));


    const EndBg = styled(Grid)(({ theme }) => ({
      backgroundImage: `url(./assets/backgroundsSvg/questionaryBg.svg)`,
          backgroundSize: "cover",
          borderBottomLeftRadius: "50px",
          borderBottomRightRadius: "50px",
          backgroundColor: "#F9F9F9",
        }));

        const DashFilter= styled(Select)(({ theme }) => ({
          borderRadius: '16px',
          backgroundColor: theme.palette.background.paper,
          cursor: 'pointer',
          color: theme.palette.text.primary,
          border: '1px solid #fff',
          height: '35px',
          "&:hover": {
            backgroundColor: purple.alpha30,
            borderColor: purple.main,
            color: theme.palette.text.primary,
          },
          "& .MuiSelect-outlined.MuiSelect-select.MuiSelect-selectMenu:focus": {
            outline: "none",
            borderColor: `${purple.main} !important`,
            boxShadow: `0 0 0 2px ${purple.alpha30} !important`,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: `${purple.main} !important`,
            borderWidth: '0px!important',
            boxShadow: `0 0 0 2px ${purple.alpha30} !important`,
          },
        }));


    const HideDivider = () => {
      const theme = useTheme();
      return theme.breakpoints.down('sm');
    };
    const InfoButton = styled(IconButton)({
      backgroundColor: '#fff', // 
          width: '22px',
          borderRadius:'5px',height:'22px',
          padding:'3px',
          fontSize: 'small',
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" ,
          "&:hover": {
            backgroundColor: purple.main,
            color:`#fff !important`,
          "path":{fill:`#fff !important`,
          stroke:`#fff !important`,}
          }
   

    })
    const NavbarButton = styled(ButtonBase)({
      // Additional styles for navbar buttons
      alignItems: "center",
      borderRadius: '100px',
      display: "flex",
      justifyContent: "flex-start",
      paddingRight: "12px!important",
      paddingLeft: "12px!important",
      padding: "6px",
      textAlign: "left",
      width: "100%",
      color:'rgb(83, 91, 102)',
      marginTop:'8px',
      '& .MuiBox-root':{margin:'0 10px 0 0', 
      lineHeight: "24px",
      whiteSpace: "nowrap",fontWeight:'400', 
      alignItems: "center",
      display: "inline-flex",
      justifyContent: "flex-start",
      '& .MuiSvgIcon-root':{color:'rgb(83, 91, 102)!important',
        '& path': {
          fill: 'rgb(83, 91, 102)',
        },'& rect': {
          fill: 'rgb(83, 91, 102)',
        }},
      
    },
      '& .MuiTouchRipple-root':{margin:'0', color:'rgb(83, 91, 102)',},

      "&:hover": {
        backgroundColor: "black",
        color:`#fff !important`,
        fill:`#fff !important`,
        '& .MuiSvgIcon-root':{color:'white!important',
        '& path': {
          fill: 'white!important',
        },'& rect': {
          fill: 'white!important',
        }},
        '& .MuiBox-root .MuiSvgIcon-root':{fill:'white',color:'white!important',},
        '& .MuiTouchRipple-root':{color:'white!important',
        '& path': {
          fill: 'white!important',
        },},
        '& .MuiBox-root':{color:'white!important',},

      

      },

    });
    const StyledRouterLink = styled(NavLink)(({ theme }) => ({
            textDecoration: 'none',
            marginLeft:'15px',
            '& .MuiBox-root':{margin:'0 0px 0 0!important', color:'rgb(83, 91, 102)',},
            '&:hover':{color: `white !important`,},
      '&.active': {
        backgroundColor: 'white',
        borderColor: `${purple.main} !important`,
        border:'1px solid',
        '&:hover':{color: `white !important`,
      
        '& .MuiBox-root .MuiSvgIcon-root':{ color:`white !important`,
        '& path': {
          fill: `white!important`,
        },}
      },
        '& .MuiBox-root':{color: `${purple.main} !important`,},
        color: `${purple.main} !important`, // Change to your preferred color
        '& .MuiBox-root .MuiSvgIcon-root':{margin:'0 0px 0 0!important', color:`${purple.main} !important`,
        '& path': {
          fill: `${purple.main} !important`,
        },
      },
      },
    }));
    const ResponsivePyrPS1 = styled('div')({
      width: '100%',
  height: 'auto',marginTop :'5px',
     
      '@media (max-width: 600px)': {
        height: '20px', width:'auto'// Small devices such as smartphones
      },
      '@media (min-width: 601px) and (max-width: 960px)': {
        height: '39px',width:'auto' // Medium devices such as tablets
      },
      '@media (min-width: 961px) and (max-width: 1280px)': {
        height: '62px',width:'auto' // Medium devices such as tablets
      },
      '@media (min-width: 1281px)': {
        height: '72px', width:'auto'// Large devices such as desktops
      },
    });


    const ResponsiveTriangle = styled('div')({
      width: '100%',
  height: 'auto',marginTop :'5px',
     
      '@media (max-width: 600px)': {
        height: '20px', width:'auto'// Small devices such as smartphones
      },
      '@media (min-width: 601px) and (max-width: 960px)': {
        height: '36px', width:'auto' // Medium devices such as tablets
      },
      '@media (min-width: 961px) and (max-width: 1280px)': {
        height: '50px', width:'auto' // Medium devices such as tablets
      },
      '@media (min-width: 1281px)': {
        height: '60px', width:'auto'// Large devices such as desktops
      },
    });
    const ResponsiveRound = styled('div')({
      width: '60px',
  height: '60px',
  borderRadius:'100%',
  backgroundColor:'white',
  border: '1px solid #e7e8e9',
  boxShadow: '0 -1px 11px -3px #CBCDD1',
  fontSize:'2rem',
  textAlign:'center',
  lineHeight:'60px',
  color:'#CBCDD1',
  zIndex:'1',


     
      '@media (max-width: 600px)': {
        height: '20px', width:'20px',// Small devices such as smartphones
        marginRight:'-10px',
        fontSize:'.5rem',
        lineHeight:'20px',
      },
      '@media (min-width: 601px) and (max-width: 960px)': {
        height: '45px',width:'45px' ,// Medium devices such as tablets
        marginRight:'-25px',
        fontSize:'1rem',
        lineHeight:'40px',
      },
      '@media (min-width: 961px) and (max-width: 1280px)': {
        height: '45px',width:'45px' ,// Medium devices such as tablets
        marginRight:'-35px',
        fontSize:'1.5rem',
        lineHeight:'50px',
      },
      '@media (min-width: 1281px)': {
        height: '60px', width:'60px',// Large devices such as desktops
        marginRight:'-40px',
        lineHeight:'60px',
      },
    });

export {
  IOSSwitch,
  SldCheckBox,
  PurpleButtonCap,
  PurpleButtonCapO,
  PurpleButtonCapOPre,
  PurpleButton,
  PurpleButtonO,
  BlackButton,
  BlackButtonCap,
  BlackButtonO,
  BlackButtonCapO,
  WhiteButton,
  SldTab,
  SldTabs,
  WhiteButtonCap ,
  WhiteButtonSIcon,
  SurveyClock,
  DashFilter,
  HideDivider,
  EndBg,
  LoginTextField,
  NavbarButton,
  StyledRouterLink,
  InfoButton,
  ResponsivePyrPS1,
  ResponsiveRound,
  ResponsiveTriangle,
 SurveyDue,
 CultureAvatarBig,
 CultureAvatarSmall,
 ArrowAnimated ,
 MtpCheadSmi,
 IbPrevNext,CwCode ,CwnameTrunk
  
  
};
