/**
 * @Author: Anandp
 * @Date:   2024-04-17 18:40:50
 * @Last Modified by:   Anandp
 * @Last Modified time: 2024-10-31 18:41:25
 */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import { paths } from "src/paths";
import { ReactComponent as TeamSelector } from "../../icons/untitled-ui/TeamSelector.svg";
import { ReactComponent as OrganisationNarratives } from "../../icons/untitled-ui/OrganisationNarratives.svg";
import { ReactComponent as OrganisationAnalytics } from "../../icons/untitled-ui/OrganisationAnalytics.svg";
import { ReactComponent as TeamPulse } from "../../icons/untitled-ui/teamPulse.svg";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { useUser } from "src/hooks/use-user";
import CryptoJS from 'crypto-js';
export const useSections = () => {
  const user = useUser();
  console.log("----user----", user);
  const { t } = useTranslation();
  // const dashboardPermsEn = sessionStorage.getItem("dashboardPerms");
  // const dashboardPerms = CryptoJS.AES.decrypt(dashboardPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8);
  const surveyPermsEn = sessionStorage.getItem("surveyPerms");
  const surveyPerms = CryptoJS.AES.decrypt(surveyPermsEn, user.id.toString()).toString(CryptoJS.enc.Utf8);
  return useMemo(() => {
    const items = [
      (user.tenetId !== 0 && surveyPerms && surveyPerms.includes(6)) && {
        title: "Team Selector",
        path: paths.dashboard.mtp.index,
        icon: (
          <SvgIcon fontSize="small">
            <TeamSelector />
          </SvgIcon>
        ),
      },
      (user.tenetId === 0) && {
        title: "MyTeamPulse",
        path: paths.dashboard.mtp.index,
        icon: (
          <SvgIcon fontSize="small">
            <TeamPulse />
          </SvgIcon>
        ),
      },
      ((user.roleId === 104 || user.roleId === 1000) && user.tenetId !== 0) && {
        title: "Organisation Analytics",
        path: paths.dashboard.hrAnalytics.index,
        icon: (
          <SvgIcon fontSize="small">
            <OrganisationAnalytics />
          </SvgIcon>
        ),
      },
      ((user.roleId === 104 || user.roleId === 1000) && user.tenetId !== 0) && {
        title: "Organisation Narratives",
        path: paths.dashboard.mtpOrgNarrative.index,
        icon: (
          <SvgIcon fontSize="small">
            <OrganisationNarratives />
          </SvgIcon>
        ),
      },
      (user.tenetId !== 0 || user.roleId === 1001) && {
        title: "MTP Settings",
        path: paths.dashboard.mtpSettings.index,
        icon: (
          <SvgIcon fontSize="small">
            <SettingsApplicationsIcon />
          </SvgIcon>
        ),
      },
    ].filter(Boolean); // Filter out any falsy values

    return [
      {
        subheader: "Menu",
        items,
      },
    ];
    // eslint-disable-next-line
  }, [t, user.roleId,  surveyPerms, surveyPermsEn]);
};
